import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Bulletin } from "./Bulletin";
import { Login } from "./Login";
import "./styles.css";

export default function App() {
  const { user } = useAuthenticator();

  if (user) {
    return <Bulletin />;
  }

  return <Login />;
}
