import { Authenticator, Flex, Grid, useTheme } from "@aws-amplify/ui-react";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { SignInHeader } from "./SignInHeader";
import { SignInFooter } from "./SignInFooter";

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },
  Footer,
};

export function Login() {
  const { tokens } = useTheme();

  return (
    <Grid>
      <Flex
        backgroundColor={tokens.colors.background.primary}
        justifyContent="center"
      >
        <Authenticator components={components} hideSignUp>
          {({ signOut, user }) => (
            <main>
              <h1>Welcome {user.username}</h1>
              <button onClick={signOut}>Sign out</button>
            </main>
          )}
        </Authenticator>
      </Flex>
      {/* <View height="100vh">
          <Image
            src="https://www.erwhawaii.com/hosted/images/75/a17d73194b4e6a9910905b5a885d58/bak-ALT.jpg"
            width="100%"
            height="100%"
            objectFit="cover"
          />
        </View> */}
    </Grid>
  );
}
