import { Paper, CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { theme, useStyle } from "./styles";

export default function MaterialLayout(props: { children: any }) {
  const { children } = props;
  const classes = useStyle();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Header />
        <Paper className={classes.paper}>{children}</Paper>
      </div>
      <Footer />
    </ThemeProvider>
  );
}
